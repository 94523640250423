html, body {
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edeff0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ag-overlay-loading-wrapper {
  transition: background-color 200ms linear;
}
@media (min-width: 651px) {
  .ce-block__content {
    margin: 0 0 0 72px;
  }
}

.ce-toolbar__actions {
  right: unset !important;
  left: 0 !important;
}

.ce-toolbar__content {
  margin: unset !important;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

p.preview_paragraph {
  margin: 0;
}

.text-max-lines-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-max-lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-max-lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.sdx-list {
  padding-left: 32px !important;
}

.cdx-list li {
  margin-bottom: 8px;
  line-height: 1.6em;
  padding: 0 !important;
}

.img {
  display: block;
  max-width: 100%;
}

.ag-dnd-ghost {
  display: none;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}
